import * as React from 'react';
import Button from '@mui/material/Button';
import AspectRatio from '@mui/joy/AspectRatio';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import { Lang, getTranslatedString } from './translate.js';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import './App.css';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function getLangs(handleClose) {
  let langs = [];
  let count = 0;
  for (const lang in Lang) {
    langs.push(<MenuItem key={count + 'language'} onClick={() => handleClose(Lang[lang])}>{lang}</MenuItem>);
    count++;
  }
  return langs;
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function App() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = React.useState(Lang['en']);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (lang) => {
    console.log(lang);
    setLanguage(lang);
    setAnchorEl(null);
  };
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box sx={{
      width: '80%', ml: '10%', mt: '5%'
    }}>
      <Card key='header'
        sx={{
          maxWidth: 450,
          margin: "0 auto",
          padding: "0.1em",
        }}>
        <CardContent>
          <AspectRatio objectFit="scale-down" sx={{ height: 220 }}>
            <CardMedia
              sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
              image={getTranslatedString('HeaderImage', language)}
              title="Logo"
            />
          </AspectRatio>
        </CardContent>
      </Card>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {getTranslatedString('Language', language)}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl != null}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {getLangs(handleClose)}
      </Menu>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={getTranslatedString('Times', language)}{...a11yProps(0)} />
          <Tab label={getTranslatedString('Contact', language)} {...a11yProps(1)} />
          <Tab label={getTranslatedString('Donate', language)} {...a11yProps(2)} />
          <Tab label={getTranslatedString('About', language)} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        {getTranslatedString('Times', language)}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        {getTranslatedString('Contact', language)}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        {getTranslatedString('Donate', language)}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        {getTranslatedString('About', language)}
      </CustomTabPanel>
    </Box >
  );
}

export default App;
