export const Lang = { "en": 0, "עב": 1 };

const Labels = {
    'About': ["About", "על"],
    'Times': ['Times', "זמנים"],
    "Contact": ["Contact", 'צור קשר'],
    "Donate": ["Donate", "לתרום"],
    'HeaderImage': ['./img/icon_test.jpeg', './img/icon_he.png'],
    'Language': ['Language', 'שפה'],
}

export function getTranslatedString(lbl, lng) {
    return Labels[lbl][lng];
}